<template>
  <tm-popup
    :submit="onSubmit"
    title="تفاصيل المراجعة"
    ref="popup"
    :fullscreen="false"
  >
    <vs-row>
      <vs-card>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
          class="mb-5"
        >
          <tm-input
            name="الشرح"
            type="text"
            placeholder="الشرح"
            validation-rules="required"
            v-model="subProductFeedback.desc"
            class="w-full"
          />
        </vs-col>

        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
          class="mb-5"
        >
          <tm-input
            name="التقييم"
            type="number"
            placeholder="التقييم"
            validation-rules="min_value:1|max_value:5"
            v-model="subProductFeedback.rate"
            class="w-full"
          />
        </vs-col>

        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
          class="mb-5"
        >
          <tm-select
            class="w-full"
            :options="users"
            label="full_name"
            validation-rules="required"
            name="المستخدم"
            v-model="subProductFeedback.user.id"
            :reduce="full_name => full_name.id"
          />
        </vs-col>
      </vs-card>
    </vs-row>
  </tm-popup>
</template>

<script>
import {RepositoryFactory} from "../../../repositories/ecommerceRepositoryFactory";
import {SharedRepositoryFactory} from "@/app/shared/shared-repositories/sharedRepositoryFactory";
import TmPopup from "../../../../shared/shared-components/TmPopup";
import TmInput from "../../../../shared/shared-components/TmInput";
import TmSelect from "../../../../shared/shared-components/TmSelect";

const subProductFeedbackRepository = RepositoryFactory.get("subProductFeedback");
const userRepository = SharedRepositoryFactory.get("userRepository");

export default {
  name: "StorePopup",
  data() {
    return {
      users: [{id: '', name: ''}]
    };
  },

  props: {
    subProductFeedback: {
      type: Object,
      required: true
    },
    subProductId: {
      type: String,
      required: true
    }
  },

  methods: {
    onSubmit() {
      console.log(this.subProductFeedback);
      this.$refs.popup.loading();
      this.subProductFeedback.sub_product_id = this.subProductId;
      if (this.subProductFeedback.id == null)
        this.create();
      else
        this.update();
    },
    create() {
      subProductFeedbackRepository.store(this.subProductFeedback).then((response) => {
        this.$refs.popup.close();
        this.$emit("created", response.data);
      });
    },
    update() {
      subProductFeedbackRepository.update(this.subProductFeedback).then((response) => {
        this.$refs.popup.close();
        this.$emit("updated", response.data);
      });
    },
    open() {
      this.$refs.popup.open();
    },
    deleteKeyValue(index) {
      this.subProduct.sub_product_details.details.splice(index, 1);
    },
    fetchAllUsers() {
      userRepository.fetchAllUsers('ecommerce').then(response => {
        this.users = response;
      });
    }
  },
  components: {
    TmPopup,
    TmInput,
    TmSelect
  },

  created() {
    this.fetchAllUsers();
  }
};
</script>
